import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"

import ClientOnly from "../components/ClientOnly.js"
import Cursor from "../components/Cursor.js"
import Navigation from "../components/Navigation.js"
import Hero from "../components/Hero.js"
import Footer from "../components/Footer.js"
import Card from "../components/Card.js"
import styles from '../components/AboutGrid.module.css'

import "../components/reset.css"
import "../components/global.css"

import Jacopo from "../images/injuro/team/Jacopo Large.jpeg"
import Riccardo from "../images/injuro/team/Riccardo Large.jpeg"
import Giovanni from "../images/injuro/team/Giovanni Large.jpeg"

// markup
const AboutPage = () => {
  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("default");

  function projectEnter(event) {
    setCursorText("");
    setCursorVariant("project");
  }

  function cursorLeave(event) {
    setCursorText("");
    setCursorVariant("default");
  }

  function contactEnter(event) {
    setCursorText("👋");
    setCursorVariant("contact");
  }

  function photoEnter(event) {
    setCursorText("✨");
    setCursorVariant("contact");
  }

  function linkEnter(event) {
    setCursorText("");
    setCursorVariant("link");
  }

  return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Injuro Interior Design - About Us</title>
          <meta name="description" content="INJURO - IMAGES // DESIGN" />
          <link rel="canonical" href="https://injuro.xyz/about/" />
        </Helmet>
        <Navigation onLinkEnter={linkEnter} onLinkLeave={cursorLeave} />
        <Hero text="" details={false} />
  
        <ClientOnly>
          <Cursor cursorText={cursorText} cursorVariant={cursorVariant} />
        </ClientOnly>

        <motion.section className="grid about">
          <div className="bio">
            <h2 className="h2">INJURO è un studio multidisciplinare di creativi specializzati nella creazioni di immagini digitali e nella progettazione di oggetti di design.
La squadra è composta da giovani designer e architetti appassionati di architettura, design e di tutto il processo che si nasconde dietro. 
Da creativi abbiamo molto idee, alcune buone e altre pessime. Utilizziamo le  immagini digitali per visualizzarle e progettiamo oggetti per toccarle con mano. 
Collaboriamo con architetti artisti designer per  tradurre le loro idee in immagini, e renderle realtà. </h2>
          </div>
        </motion.section>

        <motion.section className="grid">
          <div className={styles.aboutGrid}>
            <motion.div className={` ${styles.first}`}>
              <img src={Jacopo} onMouseEnter={photoEnter} onMouseLeave={cursorLeave}/>
              <h2 className="h2">Jacopo Furia</h2>
            <p className="paragraph" styles="text-align: center;">
            Interior designer, di Teramo, classe 94', si forma alla RUFA di Roma e alla SPD di Milano è appassionato di design e musica elettronica.
            </p>
            </motion.div>
            <motion.div className={` ${styles.first}`}>
              <img src={Riccardo}  onMouseEnter={photoEnter} onMouseLeave={cursorLeave}/>
              <h2 className="h2">Riccardo Manfredi</h2>
          <div className="bio-body">
            <p className="paragraph" styles="text-align: center;">
            Interior designer, nato a Roma, classe 95', si forma alla RUFA di Roma è appassionato di gaming e rendering
            </p>
          </div>
            </motion.div>
            <motion.div className={` ${styles.first}`}>
              <img src={Giovanni}  onMouseEnter={photoEnter} onMouseLeave={cursorLeave}/>
              <h2 className="h2" >Giovanni Toselli</h2>
          <div className="bio-body">
            <p className="paragraph" styles="text-align: center;">
            Architetto bolognese, classe 93', si forma al Politecnico di Milano e allo IUAV di Venezia è appassionato di architettura e grafica.
            </p>
          </div>
            </motion.div>
          </div>
        </motion.section>

        {/* <motion.section className="grid about">
          <div className="bio">
            <h2 className="h2">Blabla bla </h2>
          </div>
          <div className="bio-body">
            <p className="paragraph paragraph--large">
            Blabla bla Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla,
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla bla
            </p>
            <p className="paragraph paragraph--large">
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla.
            </p>
            <p className="paragraph paragraph--large">
            Blabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla blaBlabla bla.
            </p>
          </div>
        </motion.section> */}

        {/* <motion.section className="container next-project">
          <div className="grid">
            <h2 className="h2 grid-title">Next Project</h2>
          </div>
          <div className="grid work-grid">
            <Card
              image="https://placehold.co/00x00/911000/FFF"
              imageAlt="Project 1"
              link="/project1"
              color="pink"
              title="Project 1"
              size="normal"
              onProjectEnter={projectEnter}
              onProjectLeave={cursorLeave}
            />
            <Card
              image="https://placehold.co/00x00/911000/FFF"
              imageAlt="Project 2"
              link="/project2"
              color="blue"
              title="Project 2"
              size="normal"
              onProjectEnter={projectEnter}
              onProjectLeave={cursorLeave}
            />
          </div>
        </motion.section> */}
        <Footer onFooterEnter={contactEnter} onFooterLeave={cursorLeave} onLinkEnter={linkEnter} onLinkLeave={cursorLeave}/>

      </div>
  )
}

export default AboutPage
