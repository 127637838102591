import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion'
import { useInView } from "react-cool-inview";
import Card from '../components/Card'
import FeaturedCard from './FeaturedCard'

import BeatriceDesteSalotto from "../images/injuro/SALOTTO_2.jpg"
import LazzaroPapiBar from "../images/injuro/BAR_OPZ1.jpg"
import VGiornateCucina from "../images/injuro/CUCINA_OPZ1_old.jpg"
import DanteGarage from "../images/injuro/GARAGE_CLOSEUP1.jpg"
import MontalcinoKitchen from "../images/injuro/KITCHEN.jpg"
import CourmayerCamino from "../images/injuro/SALOTTO CAMINO.jpg"
import LombardiaScala from "../images/injuro/SCALA_OPZ2A.jpg"
import CavourSpa from "../images/injuro/SPA OPZ3 LISCIA.jpg"
import CorsicaVanoScale from "../images/injuro/VANO SCALE_OPZ2 CLOSEUP.jpg"
import SanGiminianoVanoScale from "../images/injuro/VANO SCALE.jpg"


function WorkGrid(props) {
  // const [visibleState, setVisibleState] = useState("initial");

  // Scroll Reveal
  // https://github.com/wellyshen/react-cool-inview
  // const { ref, inView, scrollDirection, entry, observe, unobserve } = useInView({
  //     threshold: 0.0, // Default is 0
  //     unobserveOnEnter: true,
  //     onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
  //       setVisibleState("visible");
  //     },
  //     onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
  //       setVisibleState("initial");
  //     }
  //   }
  // );

  const gridVariants = {
    initial: {
      opacity: 0.25,
      scale: 0.98,
      y: 60,
      transition: {
        type: "spring",
        duration: 0.8
      }
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.8
      }
    }
  };

  function projectEnter(event) {
    props.onProjectEnter();
  }

  function projectLeave(event) {
    props.onProjectLeave();
  }


  // TODO: list all the projects here

  return (
    <div className="grid">
      <div className="work-grid">
          <FeaturedCard
            image={BeatriceDesteSalotto}
            imageAlt="Project 1"
            // link="/project1" # removing link to project
            color="white"
            title="BEATRICE D'ESTE"
            key="Project1"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2023"
            projectSize="L"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            image={LazzaroPapiBar}
            // link="/project2" # removing link to project
            imageAlt="Phone displaying Discord Templates UI"
            color="white"
            title="LAZZARO PAPI"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2022"
            projectSize="S"
            alignment="bottom"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            image={CorsicaVanoScale}
            // link="/project3" # removing link to project
            imageAlt="Project 3"
            color="white"
            title="CORSICA"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2023"
            projectSize="M"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            image={DanteGarage}
            // link="/destiny-medals" # removing link to project
            imageAlt="Project 4"
            title="DANTE"
            color="white"
            size="featured"
            projectType="COMMERCIALE"
            projectYear="2023"
            projectSize="S"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            image={VGiornateCucina}
            // link="/steelseries" # removing link to project
            imageAlt="Project 5"
            title="5 GIORNATE"
            color="white"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2022"
            projectSize="L"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            // image="https://newportfolio.s3-us-west-2.amazonaws.com/server-video-image.png"
            image={MontalcinoKitchen}
            imageAlt="Project 6"
            // link="/project1" # removing link to project
            color="white"
            title="MONTALCINO"
            key="Project6"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2023"
            projectSize="M"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            // image="https://newportfolio.s3-us-west-2.amazonaws.com/server-video-image.png"
            image={LombardiaScala}
            imageAlt="Project 7"
            // link="/project1" # removing link to project
            color="white"
            title="LOMBARDIA"
            key="Project7"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2023"
            projectSize="L"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            // image="https://newportfolio.s3-us-west-2.amazonaws.com/server-video-image.png"
            image={CourmayerCamino}
            imageAlt="Project 8"
            // link="/project1" # removing link to project
            color="white"
            title="COURMAYER"
            key="Project8"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2024"
            projectSize="L"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <Card
            // image="https://newportfolio.s3-us-west-2.amazonaws.com/server-video-image.png"
            image={SanGiminianoVanoScale}
            imageAlt="Project 9"
            // link="/project1" # removing link to project
            color="white"
            title="SAN GIMINIANO"
            key="Project9"
            size="featured"
            projectType="RESIDENZIALE"
            projectYear="2022"
            projectSize="S"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
          <FeaturedCard
            image={CavourSpa}
            imageAlt="Project 10"
            // link="/project1" # removing link to project
            color="white"
            title="CAVOUR"
            key="Project10"
            size="featured"
            projectType="HOSPITALITY"
            projectYear="2022"
            projectSize="S"
            alignment="left"
            onProjectEnter={projectEnter}
            onProjectLeave={projectLeave}
          />
      </div>
    </div>
  )
}

export default WorkGrid