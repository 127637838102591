import React, { useState } from "react";
import Cursor from "./components/Cursor";
import Hero from "./components/Hero";
import WorkGrid from "./components/WorkGrid";
import AboutSection from "./components/AboutSection";
import ClientOnly from "./components/ClientOnly";
import Footer from "./components/Footer";

const Home = () => {
  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("default");
  
  function projectEnter(event) {
    setCursorText("View");
    setCursorVariant("project");
  }

  function cursorLeave(event) {
    setCursorText("");
    setCursorVariant("default");
  }

  function contactEnter(event) {
    setCursorText("👋");
    setCursorVariant("contact");
  }

  function linkEnter(event) {
    setCursorText("");
    setCursorVariant("link");
  }

  return (
    <main>
      {/* <Hero text="write something here??" details={true}/> */}
      <ClientOnly>
      
        <Cursor cursorText={cursorText} cursorVariant={cursorVariant} />
        <WorkGrid onProjectEnter={projectEnter} onProjectLeave={cursorLeave} />
        {/* <AboutSection /> */}
      </ClientOnly>
      <Footer onFooterEnter={contactEnter} onFooterLeave={cursorLeave} onLinkEnter={linkEnter} onLinkLeave={cursorLeave}/>
    </main>
  );
};

export default Home;
