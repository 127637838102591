import * as React from "react"
import { useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { motion } from "framer-motion"

import ClientOnly from "../components/ClientOnly.js"
import Cursor from "../components/Cursor.js"
import Navigation from "../components/Navigation.js"
import Card from "../components/Card.js"
import Footer from "../components/Footer.js"

import clockIcon from '../images/clock.svg'
import calendarIcon from '../images/calendar.svg'

import "../components/global.css"
import "../components/project.css"


const ServerVideo = () => {
  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("default");

  const ref = useRef()
  // const { scrollYProgress } = useViewportScroll();

  function projectEnter(event) {
    setCursorText("");
    setCursorVariant("project");
  }

  function cursorLeave(event) {
    setCursorText("");
    setCursorVariant("default");
  }

  function contactEnter(event) {
    setCursorText("👋");
    setCursorVariant("contact");
  }

  function linkEnter(event) {
    setCursorText("");
    setCursorVariant("link");
  }

  const imageVariants = {
    initial: {
      opacity: 0,
      y: 100,
      transition: {
        type: "spring",
        delay: 0.35,
        duration: 0.8,
        damping: 10,
        stiffness: 80,
        bounce: 0.2,
      }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        delay: 0.35,
        duration: 0.8,
        damping: 10,
        stiffness: 80,
        bounce: 0.2,
      }
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Injuro - Interior Design Studio - Project1 Case Study</title>
        <meta name="description" content="Case study for Discord Project1 feature." />
        <link rel="canonical" href="https://injuro.xyz/project1/" />
      </Helmet>
      <div>
        <ClientOnly>
          <Cursor cursorText={cursorText} cursorVariant={cursorVariant} />
        </ClientOnly>

        <motion.div className="project-hero-wrapper" ref={ref}>
          <Navigation onLinkEnter={linkEnter} onLinkLeave={cursorLeave} />
          <div className="project-hero">
            <ClientOnly>
              <motion.figure
                className="project-hero-image"
                initial="initial"
                animate="visible"
                variants={imageVariants}
              >
                <motion.img
                  src="https://via.placeholder.com/1800X1200"
                  alt="Four mobile phones displaying Project1 project for Discord" />
              </motion.figure>
            </ClientOnly>
          </div>
          <div className="project-hero-background pink"></div>
        </motion.div>

        <div class="grid">
          <div className="project-intro">
            <h1 className="project-title">
              Project 1
            </h1>
            <div className="project-description">
              <p className="paragraph--large project-lede">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
              </p>
            </div>
            <ClientOnly>
              <div className="detail first-detail">
                <motion.div
                  initial={{ opacity: 0, scale: 0.6, y: 0 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ type: 'spring', delay: 0.4, damping: 15 }}
                  className="icon-wrapper">
                  <img src={clockIcon} alt="Clock Icon" className="details-icon" />
                </motion.div>
                <motion.h3
                  className="details-label"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ type: 'spring', delay: 0.65, damping: 15 }}>
                  3 Months
                    </motion.h3>
              </div>
              <div className="detail second-detail">
                <motion.div
                  initial={{ opacity: 0, scale: 0.6, y: 0 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ type: 'spring', delay: 0.8, damping: 15 }}
                  className="icon-wrapper">
                  <img src={calendarIcon} alt="Calendar Icon" className="details-icon" />
                </motion.div>
                <motion.h3
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ type: 'spring', delay: 1, damping: 15 }}
                  className="details-label">Shipped May 2020
                    </motion.h3>
              </div>
            </ClientOnly>
          </div>
        </div>

        <div className="grid">
          <section className="project-main">
            <figure className="project-image project-image--full">
              <img alt="Lorem ipsum dolor sit amet" src="https://via.placeholder.com/1600X800.png" />
              {/* <StaticImage 
                alt="Project1 design on mobile and desktop devices"
                src="https://via.placeholder.com/1600X800.png"
                quality="100"
                placeholder="blurUp"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
                /> */}
            </figure>
            <div className="body">
              <h3 className="h3 project-about">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </h3>
            </div>

            <figure className="project-image project-image--full">
              <img alt="Project1 - Lorem ipsum dolor sit amet..." src="https://via.placeholder.com/1600X800" />
              {/* <StaticImage 
                alt="Two phones displaying people using Project1"
                src="https://via.placeholder.com/1600X800"
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
                /> */}
            </figure>

            <div className="body">
              <h2 className="h2">Description</h2>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="paragraph">
                We had three primary goals:
              </p>
              <ul className="case-list">
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.....</li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
              </ul>
            </div>

            <div className="image-2up">
              <div class="image-with-caption">
                <figure className="project-image project-image--half">
                  <img
                    src="https://via.placeholder.com/1200x800"
                    alt="Lorem ipsum dolor sit amet"
                  />
                  {/* <StaticImage 
                    src="https://via.placeholder.com/1200x800"
                    alt="Illustration of our three projects merging into one"
                    quality="100"
                    placeholder="blurUp"
                    objectFit="contain"
                    backgroundColor="#f9f9f9"
                    durationFadeIn="200"
                  /> */}
                </figure>
                <figcaption>Merging our three features into one.</figcaption>
              </div>
              <div className="image-with-caption">
                <figure className="project-image project-image--half">
                  <img
                    src="https://via.placeholder.com/1200x800"
                    alt="Lorem ipsum dolor sit amet, consectetur"
                  />
                  {/* <StaticImage 
                    src="https://via.placeholder.com/1200x800"
                    alt="Six people using our legacy version of Discord Video chat in 2017"
                    quality="100"
                    placeholder="blurUp"
                    objectFit="contain"
                    backgroundColor="#f9f9f9"
                    durationFadeIn="200"
                  /> */}
                </figure>
                <figcaption>Lorem ipsum dolor sit amet</figcaption>
              </div>
            </div>

            {/* <div className="body">
              <h2 className="h2">Designing a system</h2>
              <p className="paragraph">
                Project1 was going to be released on desktop, Android, and iOS at
                the same time. We wanted the feature to be familiar if you switched
                between platforms (which is common for our users) by using the
                same patterns, visual language, and components.
              </p>
              <p className="paragraph">
                We designed and developed for each platform in tandem.
              </p>
              <h3 className="h3">Videos</h3>
              <p className="paragraph">
                I started design by solving for how videos would be displayed, starting with a two person call.
              </p>
              <p className="paragraph">
                Each person had their own 16:9 tile so we wouldn’t need to letterbox their video.
                Their name and additional states were also displayed in case they were muted
                or had their camera disabled.
              </p>
            </div> */}

            <div className="image-2up">
              <div class="image-with-caption">
                <figure className="project-image project-image--half">
                  <img
                    src="https://via.placeholder.com/1200x800"
                    alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                  />
                  {/* <StaticImage 
                    src="https://via.placeholder.com/1200x800"
                    alt="Two video tiles from the Discord Project1 desktop, one with a video enabled and one with just an avatar."
                    quality="100"
                    placeholder="blurUp"
                    objectFit="contain"
                    backgroundColor="#f9f9f9"
                    durationFadeIn="200"
                  /> */}
                </figure>
              </div>
              <div className="image-with-caption">
                <figure className="project-image project-image--half">
                  <img
                    src="https://via.placeholder.com/1200x800"
                    alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                  />
                  {/* <StaticImage 
                    src="https://via.placeholder.com/1200x800"
                    alt="Two video tiles from the Discord Project1 on iOS, one with a video enabled and one with just an avatar."
                    quality="100"
                    placeholder="blurUp"
                    objectFit="contain"
                    backgroundColor="#f9f9f9"
                    durationFadeIn="200"
                  /> */}
                </figure>
              </div>
            </div>

            <figure className="project-image project-image--full">
              <img 
                alt="Project1- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800" 
                alt="A pink rectangle with a mac book in the middle, on the screen are two women having a chat over discord Project1"
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>

            <div className="body">
              <h3 className="h3">Grid View</h3>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt 
              </p>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
              </p>
            </div>

            <figure className="project-image project-image--full">
              <img 
                alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800" 
                alt="Group of people hanging out on Project1, displayed in a grid of rectangles."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>
            <figure className="project-image project-image--full">
              <img 
                alt="Mobile grid of people using video on mobile to chat together."
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800" 
                alt="Mobile grid of people using video on mobile to chat together."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>
            <div className="image-2up">
              <div class="image-with-caption">
                <figure className="project-image project-image--half">
                  <video src="https://via.placeholder.com/1200x800" muted loop />
                </figure>
              </div>
              <div className="image-with-caption">
                <figure className="project-image project-image--half">
                  <img
                    src="https://via.placeholder.com/1200x800"
                    alt="A screenshot of all the prototype flow examples tested for Project1 on mobile."
                  />
                  {/* <StaticImage 
                    src="https://via.placeholder.com/1200x800"
                    alt="A screenshot of all the prototype flow examples tested for Project1 on mobile."
                    quality="100"
                    placeholder="blurUp"
                    objectFit="contain"
                    backgroundColor="#f9f9f9"
                    durationFadeIn="200"
                  /> */}
                </figure>
              </div>
            </div>

            <div className="body">
              <h3 className="h3">Lorem ipsum dolor sit amet</h3>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt
              </p>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
              </p>
              <div className="image-with-caption project-image--inline">
                  <img
                    src="https://via.placeholder.com/1200x800"
                    alt="Three simple collection of rectangles that demonstrate the different arrangement of videos for
                      the focused, grid, and full views available in the Project1 feature."
                  />
                  {/* <StaticImage 
                    src="https://via.placeholder.com/1200x800"
                    alt="Three simple collection of rectangles that demonstrate the different arrangement of videos for
                    the focused, grid, and full views available in the Project1 feature."
                    quality="100"
                    placeholder="blurUp"
                    objectFit="contain"
                    backgroundColor="#f9f9f9"
                    durationFadeIn="200"
                  /> */}
                <figcaption>Lorem ipsum dolor sit amet, consectetur adipiscing elit</figcaption>
              </div>
            </div>

            <figure className="project-image project-image--full">
              <img 
                alt="Collection of screens and lines that show prototype behavior for Project1."
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800"
                alt="Collection of screens and lines that show prototype behavior for Project1."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>
            <figure className="project-image project-image--full">
              <img 
                alt="Macbook showing the 'Focused View' of Project1 in action with someone cooking on stream."
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800" 
                alt="Macbook showing the 'Focused View' of Project1 in action with someone cooking on stream."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>

            <div className="body">
              <h3 className="h3">Lorem ipsum dolor sit amet</h3>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </div>
            
            <figure className="project-image project-image--full">
              <img 
                alt="Lorem ipsum dolor sit amet, consectetur adipiscing"
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800"
                alt="Someone live streaming a game on Discord."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>
            <figure className="project-image project-image--full">
              <img 
                alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                src="https://via.placeholder.com/1200x800"
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800"
                alt="Screenshot of the Discord design system and all the variations of the video tiles used in the Project1 feature."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>
            <figure className="project-image project-image--full">
              <img 
                alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
                src="https://via.placeholder.com/1200x800" 
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800"
                alt="Two phones showing Project1 being used and someone watching a stream on mobile."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>

            <figure className="project-video project-image--full">
              <div className="iframe-container">
                <iframe width="560" height="315" src="https://www.youtube.com" title="Project1 Release" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </figure>
            
            <div className="body">
              <h2 className="h2">Lorem ipsum</h2>
              <p className="paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                <em>Lorem ipsum dolor sit amet, consectetur adipiscing elit</em>
              </p>
            </div>

            <figure className="project-image project-image--full">
              <img 
                alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                src="https://via.placeholder.com/1200x800"
              />
              {/* <StaticImage 
                src="https://via.placeholder.com/1200x800"
                alt="Three phones with screens displaying Project1 and young women watching their friend stream a social app."
                quality="100"
                placeholder="blurUp"
                objectFit="contain"
                backgroundColor="#f9f9f9"
                durationFadeIn="200"
              /> */}
            </figure>

          </section>
        </div>


        <motion.section className="container next-project">
          <div className="grid">
            <h2 className="h2 grid-title">Next Project</h2>
          </div>
          <div className="grid work-grid">
            <Card
              image="https://via.placeholder.com/1200x800 of Discord's Onboarding Screen"
              link="/project2"
              color="blue"
              title="Discord Onboarding"
              size="normal"
              onProjectEnter={projectEnter}
              onProjectLeave={cursorLeave}
            />
            <Card
              image="https://via.placeholder.com/1200x800 Project 3 Plugins in Figma"
              link="/destiny-tooling"
              color="green"
              title="Project 3"
              size="normal"
              onProjectEnter={projectEnter}
              onProjectLeave={cursorLeave}
            />
          </div>
        </motion.section>

        <Footer onFooterEnter={contactEnter} onFooterLeave={cursorLeave} onLinkEnter={linkEnter} onLinkLeave={cursorLeave} />
      </div>
    </div>
  )
}

export default ServerVideo
